import './CarouselGallery.scss';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { ReactNode, useEffect, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';

interface CarouselGalleryProps {
	slides: ReactNode[];
	onSlideChange: (index: number) => void;
}

interface SlideObject {
	class: string;
	element: ReactNode;
}

const CarouselGallery: React.FC<CarouselGalleryProps> = ({ slides, onSlideChange = () => {} }) => {
	const [slideTotal, setSlideTotal] = useState(0);
	const [slideCurrent, setSlideCurrent] = useState(-1);
	const [gallerySlides, setGallerySlides] = useState<SlideObject[]>([]);
	const [height, setHeight] = useState('0px');
	const handlers = useSwipeable({
		onSwiping: (eventData: SwipeEventData) => {
			if (eventData.dir === 'Right') {
				slideLeft();
			}
			if (eventData.dir === 'Left') {
				slideRight();
			}
		}
	});

	useEffect(() => {
		const locSlides: SlideObject[] = [];
		slides.forEach((slide, index) => {
			let className = 'slider-single post-active-ondeck';
			if (index === 0) {
				className = 'slider-single active';
			} else if (index === 1) {
				className = 'slider-single post-active';
			} else if (index === slides.length - 1) {
				className = 'slider-single pre-active';
			}
			locSlides.push({
				class: className,
				element: slide
			});
		});
		setGallerySlides(locSlides);
		setSlideTotal(locSlides.length - 1);
		setSlideCurrent(0);
	}, [slides]);

	const slideRight = () => {
		let preActiveSlide;
		let postActiveSlide;
		let slideCurrentLoc = slideCurrent;

		const activeClass = 'slider-single active';
		const slide: SlideObject[] = [...gallerySlides];
		if (slideTotal > 1) {
			if (slideCurrentLoc < slideTotal) {
				slideCurrentLoc++;
			} else {
				slideCurrentLoc = 0;
			}
			if (slideCurrentLoc > 0) {
				preActiveSlide = slide[slideCurrentLoc - 1];
			} else {
				preActiveSlide = slide[slideTotal];
			}
			const activeSlide = slide[slideCurrentLoc];
			if (slideCurrentLoc < slideTotal) {
				postActiveSlide = slide[slideCurrentLoc + 1];
			} else {
				postActiveSlide = slide[0];
			}

			slide.forEach((slid) => {
				if (slid.class.includes('pre-active-ondeck')) {
					slid.class = 'slider-single post-active-ondeck';
				}
				if (slid.class.includes('pre-active')) {
					slid.class = 'slider-single pre-active-ondeck';
				}
			});

			preActiveSlide.class = 'slider-single pre-active';
			activeSlide.class = activeClass;
			postActiveSlide.class = 'slider-single post-active';
			setGallerySlides(slide);
			setSlideCurrent(slideCurrentLoc);

			if (document.getElementsByClassName('slider-single active').length > 0) {
				setTimeout(() => {
					if (document.getElementsByClassName('slider-single active').length > 0) {
						const height = document.getElementsByClassName('slider-single active')[0].clientHeight;
						setHeight(`${height}px`);
					}
				}, 500);
			}
			onSlideChange(slideCurrentLoc);
		} else if (slide[0] && slide[0].class !== activeClass) {
			slide[0].class = activeClass;
			setGallerySlides(slide);
			setSlideCurrent(0);
		}
	};
	const slideLeft = () => {
		if (slideTotal > 1) {
			let preActiveSlide;
			let postActiveSlide;
			let slideCurrentLoc = slideCurrent;
			const slide: SlideObject[] = [...gallerySlides];
			if (slideCurrentLoc > 0) {
				slideCurrentLoc--;
			} else {
				slideCurrentLoc = slideTotal;
			}

			if (slideCurrentLoc < slideTotal) {
				postActiveSlide = slide[slideCurrentLoc + 1];
			} else {
				postActiveSlide = slide[0];
			}
			const activeSlide = slide[slideCurrentLoc];
			if (slideCurrentLoc > 0) {
				preActiveSlide = slide[slideCurrentLoc - 1];
			} else {
				preActiveSlide = slide[slideTotal];
			}
			slide.forEach((slid) => {
				if (slid.class.includes('post-active-ondeck')) {
					slid.class = 'slider-single pre-active-ondeck';
				}
				if (slid.class.includes('post-active')) {
					slid.class = 'slider-single post-active-ondeck';
				}
			});
			preActiveSlide.class = 'slider-single pre-active';
			activeSlide.class = 'slider-single active';
			postActiveSlide.class = 'slider-single post-active';
			setGallerySlides(slide);
			setSlideCurrent(slideCurrentLoc);
			onSlideChange(slideCurrentLoc);
			if (document.getElementsByClassName('slider-single active').length > 0) {
				setTimeout(() => {
					if (document.getElementsByClassName('slider-single active').length > 0) {
						const height = document.getElementsByClassName('slider-single active')[0].clientHeight;
						setHeight(`${height}px`);
					}
				}, 500);
			}
		}
	};

	return (
		<div className="gallery-carousel" style={{ height }} {...handlers}>
			{gallerySlides && gallerySlides.length > 0 && (
				<div className="slider-container">
					<div className="slider-content">
						{gallerySlides.map((slider: SlideObject, index: number) => (
							<div className={slider.class} key={index}>
								<div className={'slider-left'} onClick={slideLeft}>
									<div>
										<ChevronLeftIcon />
									</div>
								</div>
								<div className={'slider-right'} onClick={slideRight}>
									<div>
										<ChevronRightIcon />
									</div>
								</div>

								<div className="slider-single-content">{slider.element}</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default CarouselGallery;
