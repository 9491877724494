import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { PrimaryButton } from '../../components/Button';
import ImageCredit from '../../components/ImageCredit';
import RandomImageCarousel from '../../components/RandomImageCarousel';
import { getImageUrl } from '../../utils/imageHelper';
import { largeFeatures, smallFeatures } from './cardData';
import FeatureDialog from './FeatureDialog';
import FeatureGallery from './FeatureGallery';
import SublimationDialog from './SublimationDialog';
import { Feature } from './types';

const ProcessBox = styled('div')`
	display: flex;
	padding: 24px;
	margin-bottom: 32px;
`;

const SectionContent = styled('div')`
	margin-inline-start: auto;
	margin-inline-end: auto;
	width: 95%;
	max-width: 1240px;
`;

const LargeImage = styled('img')`
	width: 100%;
	height: 500px;
	object-fit: cover;
	border-radius: 20px;

	@media (max-width: 768px) {
		height: 300px;
	}
`;

const ImageOverlay = styled('div')`
	position: relative;
	width: 100%;
	padding: 20px 0;
	cursor: pointer;
	transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
	&:hover {
		transform: scale(1.02);
	}
`;

const OverlayContent = styled(Box)`
	position: absolute;
	bottom: 0;
	left: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin-bottom: 20px;
	padding: 25px 25px;
	text-align: left;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	z-index: 1;

	@media (max-width: 768px) {
		padding: 16px;
	}
`;

const StyledButton = styled(Button)`
	margin-top: 16px;
	:hover {
		opacity: 1;
	}

	@media (max-width: 768px) {
		margin-top: 8px;
	}
`;

const MarketingBoxContainer = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 24px;
`;

const MarketingBox = styled('div')`
	border-radius: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	background-color: #f5f5f5;
	text-align: center;
	width: 100%;
	overflow: hidden;
	cursor: pointer;
	transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
	&:hover {
		transform: scale(1.02);
	}
`;

const FullImage = styled('img')`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const MarkingBoxContent = styled('div')`
	margin-top: 24px;
`;

function Custom() {
	const navigate = useNavigate();

	const handleBuildClick = () => {
		navigate('/TeamApparel');
	};
	const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
	const [sublim, setSublim] = useState<'full' | 'solid' | null>(null);

	const handleFeatureClick = (feature: Feature) => {
		setSelectedFeature(feature);
	};

	const handleCloseDialog = () => {
		setSelectedFeature(null);
		setSublim(null);
	};

	const handleSublimClick = (subType: 'full' | 'solid') => {
		setSublim(subType);
	};

	return (
		<>
			<SectionContent>
				<h2>Check your Fit</h2>
				<ImageOverlay>
					<LargeImage src={getImageUrl('large/layout.jpg')} alt="Large" />
					<OverlayContent>
						<Typography
							variant="h4"
							component="h3"
							gutterBottom
							color={'white'}
							sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
						>
							Experience the Breakmark Difference
						</Typography>
						<Typography
							variant="body1"
							gutterBottom
							color={'white'}
							sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
						>
							High-quality custom gear for your team
						</Typography>
						<StyledButton color="white" onClick={handleBuildClick} disableHover>
							Start Your Design
						</StyledButton>
						<ImageCredit>Sloth Jackson</ImageCredit>
					</OverlayContent>
				</ImageOverlay>
			</SectionContent>

			<SectionContent style={{ paddingTop: 80 }}>
				<h2>How Breakmark Works</h2>
			</SectionContent>
			<ProcessBox className={'grey-box'} id={'DecorationProcess'}>
				<FeatureGallery features={smallFeatures} onFeatureClick={handleFeatureClick} cardType={'small'} />
			</ProcessBox>

			<SectionContent style={{ paddingTop: 70 }}>
				<h2>Our products in Action</h2>
				<ImageOverlay>
					<RandomImageCarousel />
					<OverlayContent>
						<Typography variant="h4" component="h3" gutterBottom color={'white'}>
							Experience the Breakmark Difference
						</Typography>
						<Typography variant="body1" gutterBottom color={'white'}>
							High-quality custom gear for your team
						</Typography>
						<StyledButton color="white" onClick={handleBuildClick} disableHover>
							Start Your Design
						</StyledButton>
					</OverlayContent>
				</ImageOverlay>
			</SectionContent>

			<SectionContent style={{ paddingTop: 70 }}>
				<h2>Discover Sublimation</h2>
				<MarketingBoxContainer>
					<MarketingBox onClick={() => handleSublimClick('solid')}>
						<MarkingBoxContent>
							<h6>Solid Sublimation</h6>
							<p>
								Vibrant and detailed, scaled down designs within
								<br /> a 12”x16” customizable area
							</p>
						</MarkingBoxContent>
						<FullImage src={getImageUrl('solidsub-card.jpg')} alt={''} />
					</MarketingBox>

					<MarketingBox onClick={() => handleSublimClick('full')}>
						<MarkingBoxContent>
							<h6>Full Sublimation</h6>
							<p>
								Advanced technique weaving vibrant, detailed designs
								<br /> into fabric for breathable, custom team apparel.
							</p>
						</MarkingBoxContent>
						<FullImage src={getImageUrl('fullsub-card.jpg')} alt={''} />
					</MarketingBox>
				</MarketingBoxContainer>
			</SectionContent>
			<SublimationDialog open={!!sublim} onClose={handleCloseDialog} subType={sublim} />

			<SectionContent style={{ paddingTop: 70 }}>
				<h2>Breakmark Apparel Highlights</h2>
			</SectionContent>

			<FeatureGallery features={largeFeatures} onFeatureClick={handleFeatureClick} cardType={'feature'} />

			<ProcessBox className={'centered'}>
				<PrimaryButton color={'black'} onClick={handleBuildClick}>
					Build Your Bundle
				</PrimaryButton>
			</ProcessBox>
			<FeatureDialog open={!!selectedFeature} onClose={handleCloseDialog} feature={selectedFeature} />
		</>
	);
}

export default Custom;
