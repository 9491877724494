import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import React from 'react';

import CarouselGallery from '../../components/CarouselGallery';

interface FeatureDialogProps {
	open: boolean;
	onClose: () => void;
	subType: 'solid' | 'full' | null;
}

const SublimationDialog: React.FC<FeatureDialogProps> = ({ open, onClose, subType }) => {
	return (
		<StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogContent>
				<div style={{ overflow: 'hidden', display: 'flex', aspectRatio: '1.5' }}>
					{subType === 'solid' && (
						<CarouselGallery
							slides={[
								<img
									key={'full-4'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_SizeLimit.jpg'}
									alt={'Size Limit'}
								/>,
								<img
									key={'full-5'}
									src={
										'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_VersatileBackgrounds.jpg'
									}
									alt={'Versatile Backgrounds'}
								/>,
								<img
									key={'full-6'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_TexturesPatterns.jpg'}
									alt={'Textures Patterns'}
								/>,
								<img
									key={'full-7'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_CustomFont.jpg'}
									alt={'Custom Font'}
								/>,
								<img
									key={'full-1'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_OffCenterDesigns.jpg'}
									alt={'Off Center Designs'}
								/>,
								<img
									key={'full-2'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_Names_Numbers.jpg'}
									alt={'Names and Numbers'}
								/>,
								<img
									key={'full-3'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/solid/Solid_CustomShorts.jpg'}
									alt={'Custom Shorts'}
								/>
							]}
							onSlideChange={() => {}}
						/>
					)}
					{subType === 'full' && (
						<CarouselGallery
							slides={[
								<img
									key={'full-5'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_LimitlessColor.jpg'}
									alt={'Limitless Color'}
								/>,
								<img
									key={'full-6'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_CompleteGarment.jpg'}
									alt={'Complete Garment'}
								/>,
								<img
									key={'full-7'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_Landscape.jpg'}
									alt={'Landscape'}
								/>,
								<img
									key={'full-8'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_Scaling.jpg'}
									alt={'Scaling'}
								/>,
								<img
									key={'full-1'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_VibrantLight.jpg'}
									alt={'Vibrant Light'}
								/>,
								<img
									key={'full-2'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_BlendedIllustration.jpg'}
									alt={'Blended Illustration'}
								/>,
								<img
									key={'full-3'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_Names_Numbers.jpg'}
									alt={'Names and Numbers'}
								/>,
								<img
									key={'full-4'}
									src={'https://assets.breakmark.com/pub/upload/sublimations/full/Full_GradientShorts.jpg'}
									alt={'Gradient Shorts'}
								/>
							]}
							onSlideChange={() => {}}
						/>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogActions>
		</StyledDialog>
	);
};

const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		border-radius: 22px;
	}
	.MuiDialogContent-root {
		padding: 0;
	}
	.MuiDialogActions-root {
		position: absolute;
		top: 0;
		right: 0;
	}
`;

export default SublimationDialog;
